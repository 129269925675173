import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
export const CourseSelectorHeadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	.course-selector-heading {
		margin: 0.5rem 0;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.course-selector-heading {
			margin: 1rem 0;
		}
	}
`;

export const CourseSelectorWrapper = styled.div`
	margin: 5rem 0;
`;
