import styled from "styled-components";
import {
	TABLET_M_BP,
	DESKTOP_L_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
const isB2u = process.env.GATSBY_BUSINESS_VERTICAL === "b2u";

// card
export const CardIconWrapper = styled.figure`
	margin: 0;
	display: inline-block;
	line-height: 0;
	border-radius: 8px;
`;
export const CardHeadingWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	margin-bottom: 3px;
`;

export const CardsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	row-gap: 3.125rem;
	margin: 1.25rem 0;
	justify-content: ${({ isB2u }) => (isB2u ? "center" : "flex-start")};
	@media only screen and (max-width: ${DESKTOP_L_BP}px) {
		margin: 2rem 0;
		justify-content: ${({ isB2u }) => (isB2u ? "center" : "start")};
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		margin: 2rem 0;
		justify-content: center;
	}
`;
export const CardWrapper = styled.div`
	box-shadow:
		0px 78px 31px rgba(0, 0, 0, 0.01),
		0px 44px 26px rgba(0, 0, 0, 0.02),
		0px 20px 20px rgba(0, 0, 0, 0.03),
		0px 5px 11px rgba(0, 0, 0, 0.04),
		0px 0px 0px rgba(0, 0, 0, 0.04);
	border-radius: 0.5rem;
	padding: 1.875rem 1.5rem 2.188rem;
	width: ${isB2u ? "240px" : "322px"};
	display: flex;
	flex-direction: column;
	.course-selector-program-desc {
		margin: ${isB2u ? "1.25rem" : "1.75rem"} 0;
	}
	.course-selector-program-heading-wrapper {
		display: flex;
		gap: 0.313rem;
		flex-direction: column;
	}
	.course-selector-program-action {
		width: ${isB2u ? "fit-content" : "50%"};
		${(props) => !props.hasDescription && `margin-top:1.25rem;`}
	}
`;
