import React, { Fragment } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";

import {
	Button,
	Icon,
	Text,
	Badge,
	VerticalTab,
	VerticalTabItem,
	Separator,
	Accordion,
	CloudinaryImage,
	LineGraphic,
	RequestSyllabusButton,
} from "@website-builder/ui/shared/elements";
import { DESKTOP_L_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

//styles
import {
	CourseTabsWrapper,
	CourseTabsResponsiveViewWrapper,
	CourseContentHeadingWrapper,
	CourseContentWrapper,
	CourseItemsWrapper,
	CourseItemWrapper,
	CourseFooterContainerWrapper,
	AccordionItemHeadingWrapper,
	AccordionItemIconWrapper,
} from "./styles";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const CourseSelectorTabs = ({
	courseSelectorFooter,
	courseSelectorContent,
	courseSelectorTitle,
	globalVariableWithURL,
	pageSection,
	context,
	contentReference,
	workshopId = "",
}) => {
	const isDesktopOver1400 = useMediaQuery(`(min-width:${DESKTOP_L_BP}px)`);

	const onChange = () => {
		contentReference?.current?.scrollIntoView({ behavior: "smooth" });
	};

	// Mobile View Accordion Component
	const AccordionHeading = ({ icon, heading }) => {
		return (
			<AccordionItemHeadingWrapper>
				{icon && (
					<AccordionItemIconWrapper>
						<Icon className="list-item-icon" iconName={icon} size={"1.5rem"} />
					</AccordionItemIconWrapper>
				)}
				<Text
					color={{
						base: "primary",
						color: "neutral-nightshade",
						shade: "800",
					}}
					variant="headline_5"
				>
					{heading}
				</Text>
			</AccordionItemHeadingWrapper>
		);
	};

	// generates accordion items array for <Accordion/>
	const generateAccordionDetails = () => {
		return courseSelectorContent.map((course) => {
			return {
				heading: (
					<AccordionHeading heading={course?.name} icon={course?.icon} />
				),
				content: <CourseContent {...course} />,
			};
		});
	};

	// Course Components

	const CourseContent = ({
		heading,
		description: desc,
		items,
		media,
		name,
	}) => {
		const description = richTextResolver(desc, globalVariableWithURL);
		return (
			<CourseContentWrapper>
				{media && (
					<CloudinaryImage
						className="course-item-image"
						url={media?.[0]?.image?.image}
						quality="auto"
						lazyload
						dynamic
						fetchFormat="auto"
					/>
				)}
				<CourseContentHeadingWrapper>
					<Text
						color={{
							base: "primary",
							color: "neutral-nightshade",
							shade: "800",
						}}
						variant="headline_4"
					>
						{heading}
					</Text>
					{description ? (
						<Text
							color={{
								base: "primary",
								color: "neutral-nightshade",
								shade: "600",
							}}
							tag="div"
							variant="paragraph_M"
							className="rich-text-wrapper"
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					) : null}
				</CourseContentHeadingWrapper>
				{items.length >= 1 ? (
					<CourseItemsWrapper>
						{items.map(({ title, description: desc, badge, cta }, index) => {
							const description = richTextResolver(desc, globalVariableWithURL);
							return (
								<Fragment key={`${title}+${index}`}>
									<CourseItemWrapper hasDescription={description}>
										<div className="course-item-wrapper">
											<div className="course-item-heading-wrapper">
												<Text
													color={{
														base: "primary",
														color: "neutral-nightshade",
														shade: "800",
													}}
													variant="headline_6"
													tag="h5"
												>
													{title}
												</Text>
												{badge && badge.length ? (
													<Badge
														className="course-item-badge"
														textColor={badge[0]?.textColor?.color}
														bgColor={badge[0]?.backgroundColor?.color}
														text={badge[0]?.text}
													/>
												) : null}
											</div>
											{description ? (
												<Text
													color={{
														base: "primary",
														color: "neutral-nightshade",
														shade: "600",
													}}
													tag="div"
													variant="paragraph_S"
													className="rich-text-wrapper"
													dangerouslySetInnerHTML={{ __html: description }}
												></Text>
											) : null}
										</div>
										{cta[0] ? (
											<Button
												className={"course-item-action"}
												type={cta[0]?.type}
												buttonText={cta[0]?.buttonText}
												data-redirect-to={cta[0]?.link?.link}
												disabled={cta[0]?.disabled}
												onClick={() =>
													handleCTAClick(cta[0], {
														page_section: pageSection,
														course_name: title,
														is_job_guarantee: !!(badge && badge.length),
														click_text: cta[0]?.buttonText,
														redirect_to: cta[0]?.link?.link,
													})
												}
											/>
										) : null}
									</CourseItemWrapper>
									{items.length !== index + 1 && (
										<Separator style={{ margin: "0 1px" }} />
									)}
								</Fragment>
							);
						})}
					</CourseItemsWrapper>
				) : null}
			</CourseContentWrapper>
		);
	};

	const CourseFooter = () => {
		return (
			<CourseFooterContainerWrapper className="course-footer">
				{courseSelectorFooter?.cta.map((cta, index) => {
					if (cta?.component === "RequestSyllabusWR") {
						return (
							<RequestSyllabusButton
								{...cta}
								pageSection={pageSection}
								context={context}
								workshopId={workshopId}
								cta={cta}
								modalId={`course-selector-${workshopId}-request-syllabus-modal`}
							/>
						);
					}
					return (
						<Button
							key={`${cta.buttonText}+${index}`}
							className={`course-item-action`}
							type={cta.type}
							disabled={cta.disabled}
							buttonText={cta.buttonText}
							onClick={() =>
								handleCTAClick(
									cta,
									{
										page_section: pageSection,
										redirect_to: cta?.link?.link,
										target: cta?.link?.target,
										click_text: cta?.buttonText,
									},
									{
										context,
									},
								)
							}
						/>
					);
				})}
			</CourseFooterContainerWrapper>
		);
	};

	const CourseTabs = () => {
		return (
			<CourseTabsResponsiveViewWrapper>
				{/* Desktop view */}
				<VerticalTab
					className="vertical-tabs"
					tabSpan={isDesktopOver1400 ? [2, 4] : [1, 4]}
					contentSpan={isDesktopOver1400 ? [6, 11] : [5, 12]}
					heading={courseSelectorTitle}
					variant={"withIcon"}
					onChange={onChange}
					bottomAddOn={courseSelectorFooter ? <CourseFooter /> : null}
				>
					{courseSelectorContent.map((course, index) => {
						return (
							<VerticalTabItem
								name={course?.name}
								icon={course?.icon}
								key={`${course?.name}+${index}`}
							>
								<CourseContent {...course} />
							</VerticalTabItem>
						);
					})}
				</VerticalTab>
				{/* Mobile view */}
				<div className="accordion">
					<Text
						className="accordion-heading"
						variant="paragraph_M_bold"
						color={{
							base: "secondary",
							color: "gray",
							shade: "dark",
						}}
					>
						{courseSelectorTitle}
					</Text>
					<Accordion
						showTopSeparator={true}
						showItemTopSeparator={false}
						icon={"plusMinus"}
						accordionItems={generateAccordionDetails()}
					/>
				</div>
			</CourseTabsResponsiveViewWrapper>
		);
	};

	return (
		<CourseTabsWrapper>
			<CourseTabs />
			<CourseFooter />
			<LineGraphic
				className="course-line-graphic"
				stroke="var(--primary-green-600)"
				size="25vw"
				left="0"
				bottom="0"
				x="46%"
				y="5%"
				flip
				rotate="18"
				line="single_loop_with_curl"
			/>
		</CourseTabsWrapper>
	);
};

export default CourseSelectorTabs;
