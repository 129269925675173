import React, { useRef } from "react";
import { Grid, Col, Text } from "@website-builder/ui/shared/elements";
import CourseSelectorTabs from "./CourseSelectorTabs";
import CourseSelectorCards from "./CourseSelectorCards";
//styles
import { CourseSelectorWrapper, CourseSelectorHeadingWrapper } from "./styles";

const CourseSelector = ({
	showCardView,
	label,
	headline,
	description,
	content,
	globalVariableWithURL,
	pageSection,
	context,
	isB2u = false,
	workshopId = "",
}) => {
	// content reference ti enable scroll back to top
	const contentReference = useRef(null);

	const CourseSelectorContent = () => {
		return showCardView ? (
			<CourseSelectorCards
				content={content}
				globalVariableWithURL={globalVariableWithURL}
				pageSection={pageSection}
				isB2u={isB2u}
			/>
		) : (
			<CourseSelectorTabs
				{...content}
				contentReference={contentReference}
				pageSection={pageSection}
				context={context}
				workshopId={workshopId}
			/>
		);
	};
	const hasHeadingContent = label || headline || description;
	return (
		<CourseSelectorWrapper
			className="course-selector outer-margin-wrap"
			ref={contentReference}
		>
			{hasHeadingContent ? (
				<Grid>
					<Col start={2} span={6} mdSpan={6}>
						<CourseSelectorHeadingWrapper>
							{label?.text ? (
								<Text
									style={{ color: label?.fontColor?.color }}
									variant={"label_M"}
								>
									{label?.text}
								</Text>
							) : null}
							{headline ? (
								<Text
									color={{
										base: "primary",
										color: "neutral-nightshade",
										shade: "800",
									}}
									className="course-selector-heading"
									variant={"headline_3"}
									tag="h2"
								>
									{headline}
								</Text>
							) : null}
							{description ? (
								<Text
									color={{
										base: "primary",
										color: "neutral-nightshade",
										shade: "600",
									}}
									className="rich-text-wrapper"
									variant={"paragraph_M"}
									tag="div"
									dangerouslySetInnerHTML={{ __html: description }}
								/>
							) : null}
						</CourseSelectorHeadingWrapper>
					</Col>
				</Grid>
			) : null}
			<CourseSelectorContent />
		</CourseSelectorWrapper>
	);
};

export default CourseSelector;
