import styled from "styled-components";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

export const CourseItemsWrapper = styled.ul`
	row-gap: 1.5rem;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
`;
export const CourseItemWrapper = styled.li`
	list-style-type: none;
	.course-item-wrapper {
		row-gap: 0.75rem;
		display: flex;
		flex-direction: column;
	}

	.course-item-heading-wrapper {
		display: flex;
		align-items: baseline;
	}

	@media only screen and (min-width: ${TABLET_M_BP}px) {
		display: flex;
		align-items: ${(props) => (props.hasDescription ? `start` : `center`)};
		justify-content: space-between;
		gap: 1.5rem;
		.course-item-badge {
			margin: 0 0.5rem;
		}
		.course-item-wrapper {
			width: 80%;
		}
		.course-item-action {
			width: 25%;
		}
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.course-item-heading-wrapper {
			display: flex;
			flex-direction: column-reverse;
			align-items: start;
			row-gap: 0.5rem;
		}
		.course-item-image {
			object-fit: cover;
			width: 100%;
			height: 172px;
		}
		${(props) =>
			props.actionType === "LinkWR"
				? `display:flex;
    flex-direction: row;
    align-items:center;
     `
				: `
     .course-item-action{
      width:100% !important;
      margin-top: 0.5rem;
     }
     `}
	}
`;

export const CourseTabsResponsiveViewWrapper = styled.div`
	z-index: 1;
`;

export const CourseContentHeadingWrapper = styled.div`
	row-gap: 1rem;
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
`;

export const CourseContentWrapper = styled.div`
	.course-item-image {
		object-fit: cover;
		width: 100%;
		height: 175px;
		border-radius: 0.5rem;
		margin-bottom: 2rem;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.course-item-image {
			height: 172px;
		}
	}
`;

export const CourseTabsWrapper = styled.div`
	position: relative;
	.vertical-tabs .course-footer {
		display: flex;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		margin: 0;
		.vertical-tabs,
		.course-line-graphic {
			display: none;
		}
		.accordion-heading {
			text-align: center;
			margin: 1rem 0;
		}
	}
	@media only screen and (min-width: ${TABLET_M_BP}px) {
		margin: 2rem 0;
		.accordion {
			display: none;
		}
		.course-footer {
			display: none;
		}
	}
`;
export const CourseFooterContainerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		margin: 2rem 1rem 0rem;
		row-gap: 1rem;
	}
`;

export const AccordionItemHeadingWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.563rem;
`;

export const AccordionItemIconWrapper = styled.figure`
	margin: 0;
	display: inline-block;
	line-height: 0;
`;
export const CardWrapper = styled.div`
	.card {
		background-color: ${(props) => props.bgColor && `${props.bgColor}`};
		padding: 2.5rem 2rem;
		display: flex;
		gap: 1rem;
		flex-direction: column;
		border-radius: 0.875rem;
		align-items: flex-start;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.card {
			box-shadow:
				0px 78px 31px rgba(0, 0, 0, 0.01),
				0px 44px 26px rgba(0, 0, 0, 0.02),
				0px 20px 20px rgba(0, 0, 0, 0.03),
				0px 5px 11px rgba(0, 0, 0, 0.04),
				0px 0px 0px rgba(0, 0, 0, 0.04);
		}
	}
`;
