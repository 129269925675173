import React from "react";

import {
	Button,
	Icon,
	Text,
	Badge,
	Grid,
	Col,
	CloudinaryImage,
} from "@website-builder/ui/shared/elements";
//styles
import {
	CardIconWrapper,
	CardHeadingWrapper,
	CardWrapper,
	CardsWrapper,
} from "./styles";
import { BUTTON_INTERACTION } from "@website-builder/utilities/constants/click-events.js";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";
const CourseSelectorCards = ({ content, pageSection, isB2u }) => {
	const ProgramCards = () => {
		return (
			<CardsWrapper isB2u={isB2u}>
				{content.map(
					(
						{ name, icon, badge, programName, programDescription, ctaLink },
						index,
					) => {
						if (!ctaLink.event && isB2u) {
							ctaLink.event = BUTTON_INTERACTION.CTA_CLICK;
							// This is typically course_cta_click in b2c
						}
						return (
							<CardWrapper
								key={`${name}+${index}`}
								hasDescription={programDescription}
							>
								<div className="course-selector-program-heading-wrapper">
									<CardHeadingWrapper>
										{icon ? (
											icon?.type === "image" ? (
												<CloudinaryImage
													className="logo-img"
													url={icon?.url}
													alt={icon?.alt || ""}
													quality="auto"
													lazyload
													fetchFormat="auto"
													dynamic
													sizes={{
														default: {
															height: icon?.height,
														},
													}}
												/>
											) : (
												<CardIconWrapper>
													<Icon iconName={icon} size={"37px"} />
												</CardIconWrapper>
											)
										) : null}
										{name ? (
											<Text
												color={{
													base: "primary",
													color: "neutral-nightshade",
													shade: "800",
												}}
												variant="headline_6"
												tag="h3"
											>
												{name}
											</Text>
										) : null}
									</CardHeadingWrapper>
									<Text
										color={{
											base: "primary",
											color: "neutral-nightshade",
											shade: "800",
										}}
										style={{ fontSize: "22px" }}
										variant="headline_6"
										tag={name ? "p" : "h3"}
									>
										{programName}
									</Text>
									{badge && (
										<Badge
											textColor={badge?.textColor}
											bgColor={badge?.bgColor}
											text={badge?.text}
										/>
									)}
								</div>
								{programDescription ? (
									<Text
										className="course-selector-program-desc"
										variant="paragraph_S"
										color={{
											base: "primary",
											color: "neutral-nightshade",
											shade: "600",
										}}
									>
										{programDescription}
									</Text>
								) : null}
								<Button
									type={isB2u ? "secondary" : ctaLink?.type}
									primaryColorButton={isB2u}
									className="course-selector-program-action"
									buttonText={ctaLink?.buttonText}
									disabled={ctaLink?.disabled}
									onClick={() =>
										handleCTAClick(ctaLink, {
											page_section: pageSection,
											course_name: programName || name,
											is_job_guarantee: !!badge?.text,
											click_text: ctaLink?.buttonText,
											redirect_to: ctaLink?.link?.link,
										})
									}
								/>
							</CardWrapper>
						);
					},
				)}
			</CardsWrapper>
		);
	};
	return (
		<Grid>
			<Col span={isB2u ? 12 : 11} start={isB2u ? 1 : 2} mdSpan={6}>
				<ProgramCards />
			</Col>
		</Grid>
	);
};

export default CourseSelectorCards;
